import React from 'react'
import './index.module.scss'

function Footer() {
  const links = [
    {
      name: '小爱开放平台',
      url: 'https://xiaoai.mi.com/'
    },
    {
      name: '小米IoT开发者平台',
      url: 'https://iot.mi.com'
    },
    {
      name: '小米开放平台',
      url: 'https://dev.mi.com'
    },
    {
      name: '小米生态云',
      url: 'https://cloud.mi.com'
    },
    {
      name: '小米商城',
      url: 'https://www.mi.com/'
    },
  ]
  return [
    <div styleName="container" key="Footer">
      <ul styleName="links" className="clearfix">
        {links.map((link, index) => (
          <li styleName="link-item" key={index}>
            <a href={link.url} target="_blank">{link.name}</a>
            {index !== links.length - 1
              ? <span style={{ margin: '0 5px' }}>|</span>
              : ''
            }
          </li>
        ))}
      </ul>
      <div styleName="copy-right">
        &copy; 小米AI 开放平台
        <span style={{ margin: '0 5px' }}>|</span>
        京 ICP 备 10046444 号
        <span style={{ margin: '0 5px' }}>|</span>
        京公网安备11010802020134号
      </div>
    </div>
  ]
}

export default Footer
