import React from 'react'
import './index.module.scss'

function XiaoAi() {
  return [
    <div styleName="content" key="XiaoAi" id="xiaoai">
      <div styleName="query-img" >
      </div>
      <div styleName="links">
        <a styleName="link-item link-voice" href="https://xiaoai.mi.com/voiceservice/index" target="_blank">语音服务</a>
        <a styleName="link-item link-skill" href="https://xiaoai.mi.com/skill/create/index" target="_blank">技能开发</a>
      </div>
    </div>
  ]
}

export default XiaoAi
